<template>
  <div class="card" :class="{ 'card-expand': expanded }">
    <div class="card-header">
      {{ $t('sms.infos.carrier-component.title') }}
      <!-- Mensagens por Operadora -->
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="block-el">
      <template v-if="fetched">
        <div class="card-body">
          <div class="row m-0 col-border-xl">
            <div class="col-md-2 p-20">
              <h6>{{ $t('sms.infos.carrier-component.lbl-total') }}</h6>
              <!-- Mensagens por Operadora -->
              <span class="h3 text-primary">{{ total }}</span>
              <template v-if="tim">
                <h6 class="m-t-20">TIM</h6>
                <span class="h3 text-info">{{ tim }}</span>
              </template>
              <template v-if="claro">
                <h6 class="m-t-20">CLARO</h6>
                <span class="h3 text-danger">{{ claro }}</span>
              </template>
              <template v-if="vivo">
                <h6 class="m-t-20">VIVO</h6>
                <span class="h3 text-primary">{{ vivo }}</span>
              </template>
              <template v-if="oi">
                <h6 class="m-t-20">OI</h6>
                <span class="h3 text-warning">{{ oi }}</span>
              </template>
              <template v-if="nextel">
                <h6 class="m-t-20">NEXTEL</h6>
                <span class="h3 text-danger">{{ nextel }}</span>
              </template>
              <template v-if="others">
                <h6 class="m-t-20">{{ $t('sms.infos.lbl-others') }}</h6>
                <span class="h3 text-success">{{ others }}</span>
              </template>
              <template v-if="unknown">
                <h6 class="m-t-20">
                  {{ $t('sms.infos.carrier-component.lbl-unknown') }}
                </h6>
                <!-- DESCONHECIDA -->
                <span class="h3 text-muted">{{ unknown }}</span>
              </template>
            </div>
            <div class="col-md-10">
              <template v-if="total">
                <line-chart
                  v-if="type === 'line'"
                  :chartdata="line.chartdata"
                  :options="line.options"
                  :height="chartHeight"
                />
                <doughnut-chart
                  v-else-if="type === 'pie'"
                  :chartdata="pie.chartdata"
                  :options="pie.options"
                  :height="163"
                />
              </template>
              <div v-else class="text-center table-placeholder">
                <i class="far fa-chart-bar font-size-80"></i>
                <h5 class="card-title m-t-20">{{ $t('sms.infos.none') }}</h5>
                <!-- Nenhum resultado encontrado -->
                <p>{{ $t('sms.infos.none-msg') }}</p>
                <!-- Nenhum dado de mensagem corresponde aos seus critérios de filtragem. Tente ajustar seus filtros. -->
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import LineChart from '@/components/LineChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'CarriersCard',
  components: { LineChart, DoughnutChart },
  props: ['form', 'filters'],
  data() {
    return {
      chartHeight: window.innerWidth >= 768 ? 102 : 468,
      fetched: true,
      expanded: false,
      type: 'line',
      total: 0,
      tim: 0,
      claro: 0,
      vivo: 0,
      oi: 0,
      nextel: 0,
      unknown: 0,
      others: 0,
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: ['#2fbfa0', '#ff5c75'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    if (window.innerWidth >= 1920) {
      this.chartHeight = 102;
    } else if (window.innerWidth >= 1280) {
      this.chartHeight = 174;
    } else if (window.innerWidth >= 768) {
      this.chartHeight = 244;
    } else {
      this.chartHeight = 466;
    }
    // this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.carriers = {};
      this.tim = 0;
      this.claro = 0;
      this.vivo = 0;
      this.oi = 0;
      this.nextel = 0;
      this.unknown = 0;
      this.others = 0;
      this.total = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      SmsService.getInsights({
        filters: this.filters,
        ...this.form,
        group_by: 'carrier',
        direction: 'both',
      })
        .then(
          (response) => {
            response.forEach((element) => {
              Object.keys(element.values).forEach((carrier, i) => {
                const value = element.values[carrier];
                if (!this.line.chartdata.datasets[i]) {
                  let color = '';
                  if (carrier === 'tim') {
                    color = '#399AF2';
                  } else if (carrier === 'claro') {
                    color = '#ff5c75';
                  } else if (carrier === 'vivo') {
                    color = '#635ebe';
                  } else if (carrier === 'oi') {
                    color = '#FFCE67';
                  } else if (carrier === 'nextel') {
                    color = '#F64A91';
                  } else if (carrier === 'desconhecida') {
                    color = '#fafafa';
                  } else {
                    color = '#2fbfa0';
                  }
                  this.line.chartdata.datasets[i] = {
                    label: carrier.charAt(0).toUpperCase() + carrier.slice(1),
                    borderColor: color,
                    data: [element.values[carrier]],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.carriers[carrier] = element.values[carrier];
                  this.pie.chartdata.labels.push(
                    carrier.charAt(0).toUpperCase() + carrier.slice(1),
                  );
                  this.pie.chartdata.datasets[0].backgroundColor[i] = color;
                  this.pie.chartdata.datasets[0].data[i] = value;
                } else {
                  this.line.chartdata.datasets[i].data.push(
                    element.values[carrier],
                  );
                  this.carriers[carrier] += element.values[carrier];
                  this.pie.chartdata.datasets[0].data[i] += value;
                }
                if (carrier === 'tim') {
                  this.tim += element.values[carrier];
                } else if (carrier === 'claro') {
                  this.claro += element.values[carrier];
                } else if (carrier === 'vivo') {
                  this.vivo += element.values[carrier];
                } else if (carrier === 'oi') {
                  this.oi += element.values[carrier];
                } else if (carrier === 'nextel') {
                  this.nextel += element.values[carrier];
                } else if (carrier === 'desconhecida') {
                  this.unknown += element.values[carrier];
                } else {
                  this.others += element.values[carrier];
                }
                this.total += element.values[carrier];
              });

              this.line.chartdata.labels.push(element.date_time);
            });
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
