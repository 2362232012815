<template>
  <div class="content">
    <PageHeader :title="$t('sms.infos.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <span class="m-t-2 d-inline-block col-lg-4">{{
                  $t('sms.infos.filters.title')
                }}</span>
                <div class="col-lg-8 text-right m-left">
                  <template v-if="dateType == 'custom'">
                    <div style="float: right; width: 50%; margin-left: 5px">
                      <date-range-picker
                        class="form-control"
                        v-model="dateRange"
                        :options="options"
                      />
                    </div>
                  </template>
                  <ul
                    class="nav nav-pills nav-pills-primary m-float-left"
                    id="pills-demo-sales"
                    style="float: right"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <select class="form-control" @change="changeDate">
                        <option value="day">
                          {{ $t('sms.infos.filters.time.24h') }}
                        </option>
                        <!-- 24 horas -->
                        <option value="week">
                          {{ $t('sms.infos.filters.time.7d') }}
                        </option>
                        <!-- 7 Dias -->
                        <option value="month">
                          {{ $t('sms.infos.filters.time.30d') }}
                        </option>
                        <!-- 30 Dias -->
                        <option value="custom">
                          {{ $t('sms.infos.filters.time.custom') }}
                        </option>
                        <!-- Data Customizada -->
                      </select>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row hide-m">
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <label>{{ $t('sms.infos.filters.field.label') }}</label
                  ><!-- Campo -->
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <label>{{ $t('sms.infos.lbl-value-text') }}</label
                  ><!-- Valor (Multiplos valores podem ser adicionados) -->
                </div>
              </div>
              <div v-for="(filter, n) in filters" class="form-row" :key="n">
                <div class="form-group col-lg-3 col-md-3 col-sm-12">
                  <select-input
                    v-model="filters[n].field"
                    :reduce="(field) => field.value"
                    :options="fields"
                    :clearable="true"
                  />
                </div>
                <div class="form-group col-lg-8 col-md-8 col-sm-12">
                  <select-input
                    v-if="filters[n].field == 'carrier'"
                    multiple
                    :reduce="(field) => field.value"
                    v-model="filters[n].values"
                    :options="carriers"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="filters[n].field == 'status'"
                    multiple
                    :reduce="(field) => field.value"
                    v-model="filters[n].values"
                    :options="status"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="filters[n].field == 'campaign_id'"
                    multiple
                    label="name"
                    :reduce="(field) => field.id"
                    v-model="filters[n].values"
                    :options="campaigns"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="
                      filters[n].field == 'to' || filters[n].field == 'segments'
                    "
                    multiple
                    taggable
                    v-model="filters[n].values"
                    :clearable="true"
                  />
                  <select-input
                    v-else-if="filters[n].field == 'from'"
                    multiple
                    label="short_code"
                    :reduce="(shortCode) => shortCode.short_code"
                    v-model="filters[n].values"
                    :options="shortCodes"
                    :clearable="true"
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control"
                    :placeholder="$t('sms.infos.lbl-select')"
                    disabled
                  />
                </div>
                <div class="form-group col-1">
                  <button
                    @click="removeFilter(n)"
                    class="btn btn-danger btn-sm"
                  >
                    <i class="icon dripicons-trash color-white"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button @click="addFilter" type="button" class="btn btn-primary">
                {{ $t('sms.infos.lbl-add-filter') }}</button
              ><!-- Adicionar Filtro -->
              <button
                type="button"
                class="btn btn-danger to-right"
                @click="fetchAll"
              >
                {{ $t('generic-str.filter') }}</button
              ><!-- Filtrar -->
            </div>
          </div>
        </div>
      </div>
      <div v-show="filtered" class="row">
        <div class="col-lg-6 col-xl-6">
          <tariffed-card ref="tariffed" :form="form" :filters="filters" />
        </div>
        <div class="col-lg-6 col-xl-6">
          <incoming-card ref="incoming" :form="form" :filters="filters" />
        </div>
        <div class="col-12">
          <outgoing-card ref="outgoing" :form="form" :filters="filters" />
        </div>
        <div class="col-12">
          <carriers-card ref="carriers" :form="form" :filters="filters" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import SmsService from '@/services/sms.service';
import PageHeader from '@/components/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';
import OutgoingCard from '@/components/sms/insights/OutgoingCard.vue';
import IncomingCard from '@/components/sms/insights/IncomingCard.vue';
import TariffedCard from '@/components/sms/insights/TariffedCard.vue';
import CarriersCard from '@/components/sms/insights/CarriersCard.vue';
import $ from 'jquery';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    SelectInput,
    TariffedCard,
    IncomingCard,
    OutgoingCard,
    CarriersCard,
  },
  data() {
    return {
      filtered: false,
      dateRange: {},
      dateType: '',
      fields: [
        {
          label: this.$t('sms.infos.filters.field.lbl-carrier'),
          value: 'carrier',
        }, // Operadora
        { label: 'Status', value: 'status' },
        {
          label: this.$t('sms.infos.filters.field.lbl-recipient'),
          value: 'to',
        }, // Destinatário
        {
          label: this.$t('sms.infos.filters.field.lbl-n-parts'),
          value: 'segments',
        }, // Número de Partes
        { label: this.$t('sms.infos.filters.field.lbl-sender'), value: 'from' }, // Remetente
        {
          label: this.$t('sms.infos.filters.field.lbl-campaign'),
          value: 'campaign_id',
        }, // Campanha
      ],
      carriers: [
        { label: 'TIM', value: 'tim' },
        { label: 'CLARO', value: 'claro' },
        { label: 'VIVO', value: 'vivo' },
        { label: 'OI', value: 'oi' },
        { label: 'NEXTEL', value: 'nextel' },
        { label: this.$t('sms.infos.lbl-others'), value: 'other' },
      ],
      status: [
        {
          label: this.$t('sms.infos.filters.status.lbl-pending'),
          value: 'accepted',
        }, // Pendente
        {
          label: this.$t('sms.infos.filters.status.lbl-queue'),
          value: 'queued',
        }, // Em Fila
        {
          label: this.$t('sms.infos.filters.status.lbl-sending'),
          value: 'sending',
        }, // Enviando
        { label: this.$t('sms.infos.filters.status.lbl-sent'), value: 'sent' }, // Enviado
        {
          label: this.$t('sms.infos.filters.status.lbl-error'),
          value: 'failed',
        }, // Erro
        {
          label: this.$t('sms.infos.filters.status.lbl-delivered'),
          value: 'delivered',
        }, // Entregue
        {
          label: this.$t('sms.infos.filters.status.lbl-unavailable'),
          value: 'undelivered',
        }, // Indisponivel
        {
          label: this.$t('sms.infos.incoming-component.received'),
          value: 'received',
        }, // Indisponivel
      ],
      campaigns: [],
      shortCodes: [],
      form: {
        begin_date: moment()
          .subtract(24, 'hours')
          .format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        time_window: 'hourly',
      },
      options: {
        timePicker: false,
        className: 'form-control',
        // maxSpan: {
        // days: 31,
        // },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: this.$t('generic-str.cancel'),
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      filters: [
        {
          field: this.$route.query.campaign_id ? 'campaign_id' : '',
          values: this.$route.query.campaign_id
            ? [this.$route.query.campaign_id]
            : null,
        },
      ],
    };
  },
  created() {
    this.fetchCampaigns();
    this.fetchShortCodes();
  },
  mounted() {
    const el = $(this.$el);
    el.on('apply.daterangepicker', (event, picker) => {
      this.dateRange[0] = picker.startDate;
      this.dateRange[1] = picker.endDate;
      this.form.begin_date = moment(this.dateRange[0], 'DD/MM/YYYY').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      this.form.end_date = moment(this.dateRange[1], 'DD/MM/YYYY').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      this.form.time_window = 'daily';
      // this.fetchAll();
    });
  },
  methods: {
    fetchShortCodes() {
      SmsService.getAccountShortCodes().then(
        (response) => {
          this.shortCodes = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchCampaigns() {
      SmsService.getCampaigns().then(
        (campaigns) => {
          this.campaigns = campaigns;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(e) {
      this.dateType = e.target.value;
      if (e.target.value === 'day') {
        this.form.begin_date = moment()
          .subtract(24, 'hours')
          .format('YYYY-MM-DD HH:00:00');
        this.form.end_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.form.time_window = 'hourly';
      } else if (e.target.value === 'week') {
        this.form.begin_date = moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00:00');
        this.form.end_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.form.time_window = 'daily';
      } else if (e.target.value === 'month') {
        this.form.begin_date = moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD 00:00:00');
        this.form.end_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.form.time_window = 'daily';
      } else if (e.target.value === 'custom') {
        this.form.begin_date = moment(moment(), 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.form.end_date = moment(moment(), 'DD/MM/YYYY').format(
          'YYYY-MM-DD HH:mm:ss',
        );
        this.form.time_window = 'daily';
      }
      // this.fetchAll();
    },
    fetchAll() {
      this.$refs.tariffed.fetch();
      this.$refs.incoming.fetch();
      this.$refs.outgoing.fetch();
      this.$refs.carriers.fetch();
      if (!this.filtered) this.filtered = true;
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
      // this.fetchAll();
    },
  },
};
</script>
