<template>
  <div class="card" :class="{ 'card-expand': expanded }">
    <div class="card-header">
      {{$t('sms.infos.incoming-component.title')}} <!-- Mensagens Recebidas -->
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="block-el">
      <template v-if="fetched">
        <div class="card-body">
          <template v-if="receivedMessages || optOutsMessages">
            <line-chart
              v-if="type === 'line'"
              :chartdata="line.chartdata"
              :options="line.options"
              :height="chartHeight"
            />
            <doughnut-chart
              v-else-if="type === 'pie'"
              :chartdata="pie.chartdata"
              :options="pie.options"
              :height="163"
            />
          </template>
          <div v-else class="text-center table-placeholder">
            <i class="far fa-chart-bar font-size-80"></i>
            <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5><!-- Nenhum resultado encontrado -->
            <p>{{$t('sms.infos.none-msg')}}</p><!-- Nenhum dado de mensagem corresponde aos seus critérios de filtragem. Tente ajustar seus filtros. -->
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6">
              <div class="card bg-success text-center p-3 m-b-0">
                <h5 class="text-white info-home">{{$t('sms.infos.incoming-component.received')}}</h5><!-- Recebidas -->
                <span class="text-white font-size-40 font-w-300">{{
                  receivedMessages
                }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="card bg-warning text-center p-3 m-b-0">
                <h5 class="text-white info-home">Opt-Outs</h5>
                <span class="text-white font-size-40 font-w-300">{{
                  optOutsMessages
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import LineChart from '@/components/LineChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'IncomingCard',
  components: { LineChart, DoughnutChart },
  props: ['form', 'filters'],
  data() {
    return {
      chartHeight: window.innerWidth >= 768 ? 174 : 468,
      fetched: true,
      expanded: false,
      type: 'line',
      receivedMessages: 0,
      optOutsMessages: 0,
      line: {
        chartdata: {
          labels: [],
          datasets: [
            {
              label: this.$t('sms.infos.incoming-component.received'), // Recebidas
              borderColor: '#2fbfa0',
              data: [],
              fill: false,
              lineTension: 0.1,
            },
            {
              label: 'Opt-Outs',
              borderColor: '#ffc000',
              data: [],
              fill: false,
              lineTension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [this.$t('sms.infos.incoming-component.received'), 'Opt-Outs'],
          datasets: [
            {
              backgroundColor: ['#2fbfa0', '#ffc000'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    if (window.innerWidth >= 1920) {
      this.chartHeight = 174;
    } else if (window.innerWidth >= 1280) {
      this.chartHeight = 303;
    } else if (window.innerWidth >= 768) {
      this.chartHeight = 192;
    } else {
      this.chartHeight = 422;
    }
    // this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      this.line.chartdata.datasets[0].data = [];
      this.line.chartdata.datasets[1].data = [];
      this.line.chartdata.labels = [];
      this.pie.chartdata.datasets[0].data = [];
      this.receivedMessages = 0;
      this.optOutsMessages = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      Promise.all([
        SmsService.getInsights({
          ...this.form,
          direction: 'inbound',
          filters: [
            {
              field: 'error_code',
              values: [],
            },
            ...this.filters,
          ],
        }),
        SmsService.getInsights({
          ...this.form,
          direction: 'inbound',
          filters: [
            {
              field: 'error_code',
              values: [10003],
            },
            ...this.filters,
          ],
        }),
      ])
        .then(
          (responses) => {
            responses[0].forEach((element, index) => {
              this.line.chartdata.datasets[0].data.push(element.values[0]);
              this.line.chartdata.datasets[1].data.push(
                responses[1][index].values[0],
              );
              this.line.chartdata.labels.push(element.date_time);
              this.receivedMessages += element.values[0];
              this.optOutsMessages += responses[1][index].values[0];
            });
            this.pie.chartdata.datasets[0].data.push(this.receivedMessages);
            this.pie.chartdata.datasets[0].data.push(this.optOutsMessages);
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
